import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row justify-content-center my-5" }
const _hoisted_2 = { class: "card col-12 col-md-6 col-lg-4" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "mb-3" }
const _hoisted_5 = { class: "mb-3" }
const _hoisted_6 = { class: "mb-3" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputWrapper = _resolveComponent("InputWrapper")!
  const _component_DualRingLoader = _resolveComponent("DualRingLoader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_DualRingLoader, { loading: _ctx.loading }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.loaded)
              ? (_openBlock(), _createElementBlock("form", {
                  key: 0,
                  class: "",
                  onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.Submit && _ctx.Submit(...args)), ["prevent"]))
                }, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", null, [
                      _cache[5] || (_cache[5] = _createElementVNode("label", {
                        for: "name",
                        class: "form-label"
                      }, "Name", -1)),
                      _createVNode(_component_InputWrapper, {
                        errors: _ctx.formError.name
                      }, {
                        default: _withCtx(() => [
                          _withDirectives(_createElementVNode("input", {
                            type: "text",
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.name) = $event)),
                            class: "form-control",
                            id: "name",
                            required: ""
                          }, null, 512), [
                            [_vModelText, _ctx.formData.name]
                          ])
                        ]),
                        _: 1
                      }, 8, ["errors"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", null, [
                      _cache[6] || (_cache[6] = _createElementVNode("label", {
                        for: "code",
                        class: "form-label"
                      }, "Code", -1)),
                      _createVNode(_component_InputWrapper, {
                        errors: _ctx.formError.code
                      }, {
                        default: _withCtx(() => [
                          _withDirectives(_createElementVNode("input", {
                            type: "text",
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.code) = $event)),
                            class: "form-control",
                            id: "code",
                            disabled: ""
                          }, null, 512), [
                            [_vModelText, _ctx.formData.code]
                          ])
                        ]),
                        _: 1
                      }, 8, ["errors"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", null, [
                      _cache[7] || (_cache[7] = _createElementVNode("label", {
                        for: "usd_rate",
                        class: "form-label"
                      }, "Rate", -1)),
                      _createVNode(_component_InputWrapper, {
                        errors: _ctx.formError.usdRate
                      }, {
                        default: _withCtx(() => [
                          _withDirectives(_createElementVNode("input", {
                            type: "number",
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.usdRate) = $event)),
                            class: "form-control",
                            id: "usd_rate",
                            step: "any",
                            required: ""
                          }, null, 512), [
                            [_vModelText, _ctx.formData.usdRate]
                          ])
                        ]),
                        _: 1
                      }, 8, ["errors"])
                    ])
                  ]),
                  _cache[8] || (_cache[8] = _createElementVNode("div", { class: "text-center" }, [
                    _createElementVNode("button", { class: "btn btn-dark" }, "Update Coin Entry")
                  ], -1))
                ], 32))
              : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _cache[9] || (_cache[9] = _createElementVNode("h1", { class: "text-center" }, "Unable to fetch entry", -1)),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("button", {
                      type: "button",
                      class: "btn btn-sm btn-outline-primary",
                      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.RefreshData && _ctx.RefreshData(...args)))
                    }, "Retry")
                  ])
                ]))
          ])
        ]),
        _: 1
      }, 8, ["loading"])
    ])
  ]))
}